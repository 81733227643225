@use 'styles/theme/theme' as globalTheme;

.root {
  background-color: var(--current-theme-background-color);
}

.section {
  &__separator {
    border-bottom: 1px solid var(--current-theme-header-border-color);
  }
  &__container {
    max-width: 1280px;
    margin: auto;
    padding: 18px 0 16px;

    &.noEdgePadding {
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (max-width: 1280px) {
      padding: 18px 16px 16px;

      &.noEdgePadding {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  & + & {
    .section__container {
      padding-top: 0;
    }
  }
  &:first-of-type:not(.section__separator) {
    .section__container {
      padding-top: 0;
    }
  }
}
