@use 'styles/theme/theme' as theme;

.root {
  padding: 0 0 16px 0;
}

.root + .root {
  padding: 16px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chipContainer {
  display: flex;
  align-items: center;
  overflow: auto;
}

.scrollableContent {
  display: flex;
  gap: 8px;
  align-items: center;
  padding-right: 16px;
}

.chipItem {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 6px 10px;
  cursor: pointer;
  box-shadow: none;
  white-space: nowrap;
  border-radius: 8px;
  border: 1px solid theme.$borderGrey;
  background: #FFF;

  &__selected {
    background: theme.$blueHover;
    .chipText {
      color: theme.$blue;
      font-weight: theme.$font-weight-bold;
    }
  }

  &:hover {
    text-decoration: underline;
    .chipText {
      color: theme.$blue;
    }
  }
}

.titleContainer {
  .linkIcon {
    fill: theme.$secondaryGrey !important;
  }
  &:hover {
    text-decoration: none;
    .titleText,
    .count {
      color: theme.$blue;
    }
    .count:before {
      background-color: theme.$blue;
    }
    .linkIcon {
      display: inline-block;
      fill: theme.$blue !important;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-right: 24px;

  .titleText {
    white-space: nowrap;
  }

  .count {
    display: inline-flex;
    align-items: center;
    line-height: 1.55;
    margin-top: 1px;
    &:before {
      content: ' ';
      margin: 0 4px;
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: theme.$secondaryGrey;
    }
  }

  &:hover {
    .linkIcon {
      fill: theme.$blue !important;
    }
  }
}

.content {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 16px;

  &.inline {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  &.condensed {
    grid-gap: 4px;
  }

  @media screen and (max-width: 600px) {
    gap: 6px;
  }
}

.skeleton_loader {
  margin-top: 12px;
}

.footer {
  margin-top: 32px;
}

.linkIcon {
  display: none;
  margin-bottom: 2px;
}

@media screen and (max-width: 600px) {
  .root .viewAll {
    text-decoration: none;
  }

  .linkIcon {
    display: inline-block;
  }
}
