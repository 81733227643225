@use 'styles/theme/theme' as theme;

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  width: 100%;
  &_left {
    display: flex;
    column-gap: 10px; 
    flex-shrink: 0;
    
    &__editBtn {
      .editAction {
        min-width: 90px;
      }
    }
  }
}

.shareIconButton {
  width: 34px;
  height: 34px;
  border-radius: 38px;
  background: theme.$white !important;
  svg {
    fill: theme.$secondaryGrey;
    height: 16px !important;
    width: 16px !important;
  }
}

.bookmark {
  border-radius: 38px;
  border: 1px solid theme.$tertiaryGrey;
  background: theme.$white;
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
}

.rightSide {
  display: flex;
  align-items: center;
  gap: 12px;
  
  &__castingToolPromo {
    @media screen and (max-width: 1280px) {
      display: none;
    }
  }
}
