@use 'styles/theme/theme' as theme;

.filters {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 5px;
  &__item {
    padding: 8px 10px;
    list-style: none;
    &:first-child {
      padding-left: 0;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    padding: 2px 0;
    &_selected {
      border-bottom: 2px solid var(--current-theme-tab-border-color) !important;
      border-radius: 0 !important;
    }
  }
  &__count {
    background: var(--current-theme-tab-count-background-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    min-width: 18px;
    height: 18px;
    width: 100%;
    padding: 5px;
    white-space: nowrap;
  }
  &__label {
    color: var(--current-theme-primary-text-color);
  }
  &__icon {
    flex-shrink: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    svg {
      fill: var(--current-theme-icon-tertiary-color) !important;
    }
  }
}

.popoverPaper {
  display: flex;
  justify-content: space-between;
  width: 150px;
  max-height: 300px;
  overflow: auto;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid theme.$tertiaryGrey;
  background: #fff;
  box-shadow: 0px 0px 6px 0px theme.$blackT25, 0px 0px 4px 0px theme.$blackT25;
}

.popoverItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid theme.$tertiaryGrey;
  border-radius: 0 !important;
}
