@use 'styles/theme/theme' as theme;

.root {
  background-color: var(--current-theme-background-color);
}
.header {
  max-width: 1280px;
  margin: auto;
  padding: 18px 0 16px;

  @media screen and (max-width: 1280px) {
    padding: 18px 16px 9px;
  }
}

.indexPageFilters {
  margin-bottom: 16px;
}

.navigation {
  max-width: 1280px;
  margin: auto;
  margin-top: 4px;
  @media screen and (max-width: 1280px) {
    margin: 4px -16px 0;
  }
}

.contextualFilter {
  max-width: 1280px;
  margin: auto;
  margin-top: 39px;
  margin-bottom: 29px;
  @media screen and (max-width: 1280px) {
    margin-top: 19px;
    margin-bottom: 20px;
  }
  &_entity {
    margin-bottom: 16px;
    @media screen and (max-width: 1280px) {
      margin-top: 0;
      margin-bottom: 15px;
      padding: 0 16px;
    }
  }
}

.action {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: flex-start;
}

.h1Header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &_title {
    & > span {
      margin-bottom: 0;
    }
  }
}

.children {
  max-width: 1280px;
  margin: auto;
}

.filtersFacetsWrapper {
  margin: 0 auto 10px auto;
  max-width: 1280px;
  &_filtersOnEntityPage {
    margin: 24px auto 0px auto;
    padding-bottom: 16px;
  }
  &_withoutContextualFilter {
    margin-top: 40px;
  }
}

.filterContainerDetailed {
  margin-bottom: 8px;
}

.filterGroupBox {
  margin-right: 8px;
}

.appliedFiltersWrapper {
  margin-top: 24px;
  width: 100%;
}


.footer {
  padding-top: 16px;
  max-width: 1280px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .children {
    margin: 0 16px;
  }

  .footer {
    margin: 0 16px;
  }
  
}
