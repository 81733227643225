@use 'styles/theme/theme' as globalTheme;

.root {
  overflow: auto;
}

.scrollablePageH1 {
  border: 1px solid var(--current-theme-header-border-color);
  padding: 10px;
  border-radius: 4px;
}

.title {
  font-size: inherit;
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: globalTheme.$base-font-family;
  color: var(--current-theme-primary-text-color);
  display: flex;
  align-items: flex-start;
  grid-gap: 0 10px;
  &_part {
    position: relative;
    display: inline-block;
    &__with_click {
      cursor: pointer;
    }
    &:after {
      content: attr(data-headline);
      font-size: 11px;
      font-weight: 400;
      display: block;
      white-space: pre-wrap;
    }
  }
  &_subpart {
    font-size: 11px;
    font-weight: 400;
    display: block;
    white-space: pre-wrap;
    &__enclosure {
      display: none;
    }
  }
}
