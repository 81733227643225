@use 'styles/theme/theme' as theme;

.stickyHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 115;
  background: theme.$white;
  border-bottom: 1px solid theme.$tertiaryGrey;
  padding: 0 12px;
  width: 100vw;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 theme.$blackT20;
  @media screen and (max-width: 1280px) {
    padding: 0;
  }
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 1280px;
    margin: auto;
    padding: 16px 0;
    @media screen and (max-width: 1280px) {
      padding: 16px;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__topLeft {
    display: flex;
    align-items: center;
  }
  &__topRight {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.divider {
  width: 1px;
  height: 12px;
  background: theme.$secondaryGrey;
  margin: 0 14px;
}

.menuIcon {
  fill: theme.$primaryGrey !important;
  margin-top: -1px;
}

.link {
  &:hover {
    .link__label {
      color: theme.$blue !important;
    }
    .menuIcon {
      fill: theme.$blue !important;
    }
  }
}

.logoImage {
  height: 12px;
}

.mobileSearch {
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 6px;
  width: calc(100% - 44px);
  height: 34px;
  padding: 0px 12px 0px 4px;
  border-radius: 60px;
  border: 1px solid theme.$primaryGrey;
  background-color: theme.$white;
  cursor: pointer;
  overflow: hidden;
  @media screen and (max-width: 1280px) {
    margin-left: 0;
  }
  &__searchSection {
    display: flex;
    width: 26px;
    height: 26px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: theme.$orange;

    svg {
      fill: theme.$white !important;
      flex-shrink: 0;
    }
  }
}

.shareEntity {
  span {
    background: theme.$white;
  }
}

.links {
  display: flex;
  align-items: center;
  gap: 32px;
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    &:hover {
      .link__label {
        color: theme.$blue !important;
        font-weight: 700 !important;
      }
    }
  }
}
